<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="4" class="d-none d-md-flex login__image justify-end" style="padding-top: 280px; padding-left: 200px;"></CCol>
        <CCol cols="12" md="4" class="my-4 my-sm-8 my-md-0" style="margin-top: 80px !important; padding: 0 5% !important;">
          <div class="login__container">
            <div class="login__inner">
              <div class="login__image">
                <img src="logotipo-menu.png" style="width: 80%; margin: 0 10%;" alt="Logo Oscuro">
              </div>
              <div class="login__header pt-5">
                  <h4>Inicia Sesión</h4>
              </div>
              <div class="login__subscribe d-flex justify-space-between align-center pb-8"><br /></div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="email"
                      label="Correo Electrónico"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CButton type="button" color="primary" style="width: 100%" @click="login">INICIAR SESIÓN</CButton>
                  </CCol>
                </CRow>
              </div>
              <div class="login__actions d-flex justify-space-between py-2">
                  <div class="login__remind" style="font-size: 12px;">
                  </div>
                  <div class="login__recovery-password" style="width: 100%">
                      <a href="/#/forget" class="font-weight-regular px-1" style="font-size: 12px; float: right !important;">¿Olvidaste tu contraseña?</a>
                  </div>
              </div>
            </div>
          </div></CCol>
        <CCol cols="12" md="4" class="d-none d-md-flex login__image justify-end" style="padding-top: 280px; padding-left: 200px;"></CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import store from '../../../store'
import axios from 'axios'

export default {
  name: 'Login',
  data: function () {
      return {
          email: '',
          password: '',
          remind: false,
          image: '',
          welcome: '',
          cta: ''
      }
  },
  mounted: async function() {
    
  },
  methods: {
    login: async function(){
      let params = {
        email: this.email,
        password: this.password,
        device_name: navigator.userAgent
      };

      let response = await login.login(params);

      localStorage.removeItem("token");
      localStorage.removeItem("is_administrator");
      localStorage.removeItem("is_customer");
      localStorage.removeItem("customer_id");
      localStorage.removeItem("is_collaborator");
      localStorage.removeItem("is_kam");

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("token", data.access_token);

        axios.defaults.headers.common['access-token'] = data.access_token;
        axios.defaults.headers.common['api-token'] = data.access_token;

        localStorage.setItem("show_menu", data.is_administrator);

        localStorage.setItem("is_administrator", data.is_administrator);
        localStorage.setItem("is_customer", data.is_customer);
        localStorage.setItem("customer_id", data.customer_id);
        localStorage.setItem("is_collaborator", data.is_collaborator);
        localStorage.setItem("is_kam", data.is_kam);

        localStorage.removeItem("date");
        localStorage.removeItem("page");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("visit_id");

        localStorage.removeItem("period");
        localStorage.removeItem("period_date");
        localStorage.removeItem("last_login");

        localStorage.removeItem("kam");
        localStorage.removeItem("customers");
        localStorage.removeItem("rutas");
        localStorage.removeItem("universos");
        localStorage.removeItem("generics");

        if(data.is_administrator){
          window.location.href = "/#/graphs/general"; return;
        }
        else{
          if(data.is_customer){
            window.location.href = "/#/customer-graphs/general"; return;
          }
          else{
            if(data.is_kam){
              window.location.href = "/#/welcome"; return;
            }
            else{
              window.location.href = "/#/home"; return;
            }
          }
        }
      }
      else{
        alert(response.message);
      }
    },
  }
}
</script>
